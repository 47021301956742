import { AdminLiaisonComponent } from './admin-liaison/admin-liaison.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NoAccessComponent } from './shared/components/no-access/no-access.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    data: { breadcrumb: 'Home' },
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
   // loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule)

  },
  {
    path: 'account',
    data: { breadcrumb: 'Account' },
    loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule)
  },
  {
    path: 'admin-office',
    data: { breadcrumb: 'Office Admin' },
    loadChildren: () => import('./admin-office/admin-office.module').then(mod => mod.AdminOfficeModule)
  },
  {
    path: 'admin-liaison',
    data: { breadcrumb: 'Liaison Admin' },
    loadChildren: () => import('./admin-liaison/admin-liaison.module').then(mod => mod.AdminLiaisonModule)
  },
  
  {
    path: 'admin-media',
    data: { breadcrumb: 'Media Admin' },
    loadChildren: () => import('./admin-media/admin-media.module').then(mod => mod.AdminMediaModule)
  },
  {
    path: 'admin-service-provider',
    data: { breadcrumb: 'admin-service-provider' },
    loadChildren: () => import('./admin-service-provider/admin-service-provider.module').then(mod => mod.AdminServiceProviderModule)
  },
  {
    path: 'admin-government-offcer',
    data: { breadcrumb: 'admin-government-offcer' },
    loadChildren: () => import('./admin-government-offcer/admin-government-offcer.module').then(mod => mod.AdminGovernmentOffcerModule)
  },
  {
    path: 'admin-seminar-staff',
    data: { breadcrumb: 'admin-seminar-staff' },
    loadChildren: () => import('./admin-seminar-staff/admin-seminar-staff.module').then(mod => mod.AdminSeminarStaffModule)
  },
  {
    path: 'admin-volunteer',
    data: { breadcrumb: 'admin-volunteer' },
    loadChildren: () => import('./admin-volunteer/admin-volunteer.module').then(mod => mod.AdminVolunteerModule)
  },
  {
    path: 'contact-us',
    data: { breadcrumb: 'Contact Us' },
    loadChildren: () => import('./contact/contact.module').then(mod => mod.ContactModule)
  },
  {
    path: 'admin',
    data: { breadcrumb: 'Admin' },
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule)
  },
  
  {
    path: 'notice-board',
    data: { breadcrumb: 'Nitificatons' },
    loadChildren: () => import('./notice-board/notice-board.module').then(m => m.NoticeBoardModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./gallery/gallery.module').then(m => m.GalleryModule),
    data: { breadcrumb: "Nitificatons" }
  },
  {
    path: 'profile',
    data: { breadcrumb: 'Profile' },
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'page',
    data: { breadcrumb: 'Page' },
    loadChildren: () => import('./page/page.module').then(m => m.PageModule)
   // loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
    data: { breadcrumb: "No Access" }
  },
  
  {
    path: '404',
    component: NotFoundComponent,
    data: { breadcrumb: "404 Error" }
  },
  
  {
    path: '**',
    redirectTo: '/404',
    data: { breadcrumb: "404 Error" }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
