import { SharedModule } from './shared/shared.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxChartsModule } from '@swimlane/ngx-charts';




export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [
    AppComponent,


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxChartsModule,
    QRCodeModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:49314", 'vgadmin.orpgujarat.com',"vgapp.vibrantgujarat.com","localhost:8100","localhost:4200", "localhost:44366", 'vg2022.orpgujarat.com', 'apivg2022.orpgujarat.com', 'api.rojgarsetu.org'],
        //disallowedRoutes: [""],

      }
    }),
    SharedModule
  ],
  providers: [
    {
      //provide: 'API_BASE_URL', useValue: 'https://localhost:44366/'
      //provide: 'API_BASE_URL', useValue: 'https://apivg2022.orpgujarat.com/'
      provide: 'API_BASE_URL', useValue: 'https://vgapp.vibrantgujarat.com/'
      
      //https://api.rojgarsetu.org/
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
