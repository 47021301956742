import { DataService } from './../data.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CmsPageService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/cms-pages', httpClient, toastrService);
  }

  getBySlug(slug: string) {
    return this.httpClient.get(this.baseUrl + 'api/cms-pages/by-slug?slug=' + slug)
      .pipe(catchError(this.handleError.bind(this)))
  }

  GetListofSlickCarousel(slug: string) {
    return this.httpClient.get(this.baseUrl + 'api/vgslick-carousels/list-of-slick-carousel?category=' + slug)
      .pipe(catchError(this.handleError.bind(this)))
  }

}