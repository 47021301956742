import { IRegistration } from './../../_models/account/IRegistration';
import { IConfirmRegistrationWithOtp, IRegistrationWithOtp } from './../../_models/account/IUser';
import { IChangePassword, IConfirmEmail, IForgotPassword, ILogin, IResetPassword } from '../../_models/account/IUser';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../_api/data.service';

@Injectable({
  providedIn: "root"
})
export class AuthService extends DataService {

  constructor(
    httpClient: HttpClient,
    private jwtHelper: JwtHelperService,
    private router: Router,
    @Inject('API_BASE_URL') private _baseUrl: string,
    toastrService: ToastrService) {
    super(_baseUrl + 'api/authorize', httpClient, toastrService);
  }

  login(login: ILogin) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/login', login)
      .pipe(catchError(this.handleError.bind(this)))
  }

  confirmEmail(confirmEmail: IConfirmEmail) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/confirm-email', confirmEmail)
      .pipe(catchError(this.handleError.bind(this)))
  }

  registrationWithOtp(emailLogin: IRegistrationWithOtp) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/registration-with-otp', emailLogin)
      .pipe(catchError(this.handleError.bind(this)))
  }

  confirmRegistrationWithOtp(confirmEmailLogin: IConfirmRegistrationWithOtp) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/confirm-registration-with-otp', confirmEmailLogin)
      .pipe(catchError(this.handleError.bind(this)))
  }
  
  confirmresetwithotp(confirmEmailLogin: IConfirmRegistrationWithOtp) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/reset-with-otp', confirmEmailLogin)
      .pipe(catchError(this.handleError.bind(this)))
  }
  forgotPassword(forgotPassword: IForgotPassword) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/forgot-password', forgotPassword)
      .pipe(catchError(this.handleError.bind(this)))
  }

  resetPassword(resetPassword: IResetPassword) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/reset-password', resetPassword)
      .pipe(catchError(this.handleError.bind(this)))
  }
  enableduserlogin(resetPassword: IResetPassword) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/enabled-userlogin', resetPassword)
      .pipe(catchError(this.handleError.bind(this)))
  }
  
  registration(resource: IRegistration) {
    return this.httpClient.post(this._baseUrl + 'api/authorize/registration', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  getProfile() {
    return this.httpClient.get(this._baseUrl + 'api/authorize/profile')
      .pipe(catchError(this.handleError.bind(this)))
  }

  changePassword(changePassword: IChangePassword) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/change-password', changePassword)
      .pipe(catchError(this.handleError.bind(this)))
  }

  logout() {
    if(typeof localStorage !== "undefined")
      localStorage.removeItem('token');
    this.router.navigate(['/account/login']);
  }

  isLoggedIn() {
    return !this.jwtHelper.isTokenExpired();
  }

  get currentUser() {
    let token = (typeof localStorage !== "undefined") ? localStorage.getItem('token') : null;
    if (!token)
      return null;
    return this.jwtHelper.decodeToken(token);
  }

  get getToken() {
    let token = (typeof localStorage !== "undefined") ? localStorage.getItem('token') : null;
    if (!token)
      return null;
    return token;
  }

  get currentUserId() {
    let token = (typeof localStorage !== "undefined") ? localStorage.getItem('token') : null;
    if (!token)
      return null;
    return this.jwtHelper.decodeToken(token)['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
  }

  isInRole(role: string) {
   
    let roles = this.currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
   
    if (roles) {
      if (typeof roles == 'object' && roles.filter((x: string) => x == role) == role) {
        return true;
      }
      else if (typeof roles == 'string' && roles == role) {
        return true;
      }
    }
    return false;
  }

  
  redirectToHome() {
    if (this.isLoggedIn() && this.isInRole("user")) {
      this.router.navigate(['/jobseeker']);
    }
    else if (this.isLoggedIn() && this.isInRole("employer")) {
      this.router.navigate(['/employer']);
    }
    else if (this.isLoggedIn() && this.isInRole("district")) {
      this.router.navigate(['/district']);
    }
    else if (this.isLoggedIn() && this.isInRole("state")) {
      this.router.navigate(['/state']);
    }
    else if (this.isLoggedIn() && this.isInRole("regional")) {
      this.router.navigate(['/regional']);
    }
  }
  redirectToEmployer() {
    if (this.isLoggedIn() && this.isInRole("user")) {
      return 1;
    }
    else if (this.isLoggedIn() && this.isInRole("employer")) {
      return 2;
    }
    else if (this.isLoggedIn() && this.isInRole("district")) {
      return 3;
    }
    else if (this.isLoggedIn() && this.isInRole("state")) {
      return 4;
    }
    else if (this.isLoggedIn() && this.isInRole("regional")) {
      return 5;
    }
    else
    {
      return 6;
    }
  }
}
