import { LoaderInterceptorService } from './services/loader-interceptor.service';
import { BOOLTSTRAP_ICONS } from './../_common/bootstrap-icons';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { CountdownModule } from 'ngx-countdown';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgxPrintModule } from 'ngx-print';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { NgsRevealModule } from 'ngx-scrollreveal';

import { NoAccessComponent } from './components/no-access/no-access.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ErrorFieldComponent } from './components/error-field/error-field.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { UserBarComponent } from './components/user-bar/user-bar.component';
import { SearchComponent } from './components/search/search.component';
import { MenuComponent } from './components/menu/menu.component';
import { FooterBarComponent } from './components/footer-bar/footer-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CustomAdapter, CustomDateParserFormatter } from '../_common/custom-date-formatter';
import { NgbTimeStringAdapter } from '../_common/custom-time-formatter';
import { DebounceClickDirective } from './directives/double-click-disable.directive';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PaginateComponent } from './components/paginate/paginate.component';
import { I18nModule } from '../i18n/i18n.module';
import { MenuItemComponent } from './components/menu-item/menu-item.component';


@NgModule({
  declarations: [
    NoAccessComponent,
    NotFoundComponent,
    LoaderComponent,
    ErrorFieldComponent,
    FooterComponent,
    FooterBarComponent,
    MenuComponent,
    SearchComponent,
    UserBarComponent,
    PaginationComponent,
    PaginateComponent,
    BreadcrumbComponent,
    LoaderComponent,
    DebounceClickDirective,
    MenuItemComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    I18nModule,
    NgbModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
      progressBar: true
    }),
    NgxBootstrapIconsModule.pick(BOOLTSTRAP_ICONS),
    SlickCarouselModule,
    CountdownModule,
    CdkStepperModule,
    NgsRevealModule,
    NgxPrintModule,
    InfiniteScrollModule
    
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    ToastrModule,
    SlickCarouselModule,
    NgxBootstrapIconsModule,
    CountdownModule,
    CdkStepperModule,
    NgxPrintModule,
    InfiniteScrollModule,

    NgsRevealModule,

    NoAccessComponent,
    NotFoundComponent,
    LoaderComponent,
    ErrorFieldComponent,
    FooterComponent,
    FooterBarComponent,
    MenuComponent,
    SearchComponent,
    UserBarComponent,
    PaginationComponent,
    PaginateComponent,
    BreadcrumbComponent,
    LoaderComponent,
    I18nModule
  ],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: 'en-IN' },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true}
  ]
})
export class SharedModule { }
