import { Component, Input, OnInit } from '@angular/core';
import { IMenuContent } from 'src/app/_models/cms/IMenu';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
  @Input() child: IMenuContent;
  constructor() { }

  ngOnInit(): void {
  }

}
