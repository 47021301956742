import { AuthService } from './../../../account/services/auth.service';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { IMenu, IMenuContent } from 'src/app/_models/cms/IMenu';
import { TranslateService } from '@ngx-translate/core';
import { MenusService } from 'src/app/_api/services/cms/menus.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, AfterViewInit {
  @Input() class: string = "navbar-dark bg-dark bg-transparent";
  @Input() header: string = "header-absolute";
  @Input() top: string = "";
  @Input() hideMenu: boolean = false;
  @Input() hideUser: boolean = false;
  @Input() hideCall: boolean = false;
  @ViewChild('stickyMenu') menuElement: ElementRef;
  sticky: boolean = false;
  elementPosition: any;
  isCollapsed: boolean = true;
  isSearch: boolean = false;

  
  primaryMenuContents: IMenuContent[];

  _authService;
  _translate;
  currentLang;
  constructor(
    public translate: TranslateService,
    private menuService: MenusService,
    private authService: AuthService
    ) {
      this._translate=  this.translate;
  }

  ngOnInit(): void {
    //this.getMenu();
  }

  ngAfterViewInit() {
    if(this.menuElement)
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll > this.elementPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }



  
  getMenu() {
    if(this.translate.currentLang == 'gu')
    {
    this.menuService.getMenuById(2).subscribe((response: IMenu) => {
      if (response) {
        this.primaryMenuContents = response.menuContents.filter(x => x.parentMenuContentId == null)
          .sort(this.GetSortOrder("order"));
      }
    });
  }else
  {
    this.menuService.getMenuById(1).subscribe((response: IMenu) => {
      if (response) {
        this.primaryMenuContents = response.menuContents.filter(x => x.parentMenuContentId == null)
          .sort(this.GetSortOrder("order"));
      }
    });
  }
  }

  GetSortOrder(prop) {
    return (a, b) => {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }
}
