import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DynamicTemplate, RuntimeCompilerService } from 'src/app/page/services/runtime-compiler.service';
import { CmsPageService } from 'src/app/_api/services/cms-page.service';
import { CmsPage } from 'src/app/_models/cms/ICmsPage';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  
skeleton: boolean = true;
cmsPage: CmsPage;
slug: string;
subscription: Subscription;
dynamicTemplate: DynamicTemplate;
year: number = new Date().getFullYear();
constructor(private router: Router,
  private route: ActivatedRoute,
  private cmsPageService: CmsPageService,
  private runtimeCompilerService: RuntimeCompilerService) { this.subscription = this.route.params.subscribe(
    (params: Params) => {
      this.slug = params.slug;
      this.getCmsPage();
    });}

ngOnInit(): void {
}
compileContent(text) {
  this.runtimeCompilerService.createAndCompileTemplate(this, text).then(data => {
    this.dynamicTemplate = data;
  });
}

getCmsPage() {
   this.cmsPageService.getBySlug('FooterComponent').subscribe((response: CmsPage) => {
    if (response) {
      this.cmsPage = response;
      this.cmsPage.content=this.cmsPage.content+"   <style>"+this.cmsPage.metaDescription+"</style>";
      this.compileContent(this.cmsPage.content);
    }
  },
    (error) => console.log('HTTP Error', error),
    () => this.skeleton = false);
}

ngOnDestroy() {
  this.subscription.unsubscribe()
}

}